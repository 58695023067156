
<template>
    <div>
        <van-pull-refresh v-model="refreshing" @refresh="onRefresh">
            <van-list v-model="loading" :finished="finished" @load="onLoad(page)">
                <div class="clear" @click="clearAll">
                    <img src="@/assets/img/xiaoxinew.png" />
                    <div class="col_0ABBB9">一键已读</div>
                </div>

                <div class="content" v-if="list.length">
                    <div v-for="(item,index) in list" :key="index">
                        <div class="time" v-if=" (index > 0 && item.time_group != list[index -1].time_group) || index == 0">{{item.time_group}}</div>
                        <div class="block" @click="clickBlock(index,item.id)">
                            <div class="top">
                                <div class="title">
                                    <div class="tips col_0ABBB9">
                                        <img src="@/assets/img/xiaoxphone.png" :class="item.notify_type" />
                                        <div class="red" v-if="!item.is_read"></div>
                                    </div>
                                    <div>{{item.notify_title}}</div>
                                </div>
                                <div class="right">
                                    <div>{{item.gmt_create}}</div>
                                    <div :class="['iconfont', selectFlag(selectList,index) ? 'icon-a-jiantouxia' : 'icon-a-jiantouyou']"></div>
                                </div>
                            </div>
                            <pre class="bottom">{{selectFlag(selectList,index) ? item.notify_data : item.notify_data_less}}</pre>
                        </div>
                    </div>
                </div>
                <div v-else class="null-data block">
                    <div>
                        <img :src="src + 'null_index.png'" />
                        <div>暂无消息</div>
                    </div>
                </div>
            </van-list>
        </van-pull-refresh>
    </div>
</template>
<script>
import { Toast } from 'vant';
import "@/assets/css/index.css";
import config from "@/utils/config.js";
export default {
    data() {
        return {
            list: [],
            selectList: [],
            src: `${config.ossImageUrl}`,
            // 分页=======================================
            loading: false,
            finished: false,
            refreshing: true,
            limit: 10,
            page: 1, //请求第几页
            total: 0, //总共的数据条数
            // ==========================================
        }
    },
    mounted() {
        this.$store.commit("getWxSDK");
        this.$store.commit("hideTabbar");
        this.loading = false;
        this.getList()
    },
    methods: {
        selectFlag(array = [], key) {
            var index = array.indexOf(key);
            if (index > -1) {
                return true
            } else { //没有选中
                return false
            }
        },
        onLoad(page) {
            this.finished = false;
            //this.loading = false;
            // 重新加载数据
            // 将 loading 设置为 true，表示处于加载状态
            this.loading = true;
            // this.getList()
            if (page != 1) {
                this.getList(page);
            }
        },
        onRefresh() {
            // 清空列表数据
            this.finished = false;
            // 重新加载数据
            // 将 loading 设置为 true，表示处于加载状态
            this.loading = true;
            this.page = 1;
            this.getList();
        },
        clickBlock(key, id) {
            var index = this.selectList.indexOf(key)
            if (index > -1) {
                this.selectList.splice(index, 1)
            } else {
                if (!this.list[key].is_read) {
                    this.Request('client/notify/read', 'put' ,{
                        notify_id: id
                    }).then(res => {
                        if (res.status == 0) {
                            this.selectList.push(key)
                            this.list[key].is_read = 1
                        }
                    })
                } else {
                    this.selectList.push(key)
                }

            }
        },
        // 一键已读
        clearAll() {
            this.Request('client/notifys/read','put',{
                team_id: localStorage.getItem('team_id')
            }).then(res => {
                if (res.status == 0) {
                    this.list.filter((v, i) => {
                        this.list[i].is_read = 1
                    })
                    Toast.success('操作成功');
                    this.list = [...this.list]
                }
            })
        },
        getList(page = 1) {
            if (page == 1) {
                this.list = []
            }
            if (this.refreshing) {
                this.list = [];
                this.refreshing = false;
            }
            var params = {
                team_id: localStorage.getItem('team_id') || '',
                page,
                limit: this.limit
            }
            this.Request('client/notifys/jobseeker','get',params).then(res => {
                if (res.status == 0) {
                    if (res.data.list.length) {
                        res.data.list.filter((v, index) => {
                            res.data.list[index].notify_data = v.notify_data.replace(/\\n/g, '\n')
                            res.data.list[index].notify_data_less = v.notify_data.split('\n')[0]
                        })
                    }
                    this.page++
                    this.list = this.list.concat(res.data.list)
                    this.loading = false;
                    if (this.list.length >= res.data.count) {
                        this.finished = true;
                    }

                }
            })
        },
    }
}
</script>
<style scoped>
.clear {
    background-color: #fff;
    position: fixed;
    width: 100%;
    height: 80px;
    line-height: 80px;
    z-index: 3;
    padding: 0 30px;
    align-items: center;
    display: flex;
    color: #4c94f6;
}
.clear img {
    width: 30px;
    height: 30px;
    margin-right: 20px;
}
.content {
    padding-top: 80px;
}
.time {
    text-align: center;
    color: #999;
    font-size: 26px;
    padding: 16px 0;
}
.content .block {
    background-color: #fff;
    margin: 0 30px 30px;
    padding: 30px;
    border-radius: 10px;
}
.content .block .top {
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid #f8f8f8;
    padding-bottom: 20px;
    line-height: 48px;
}
.content .block .top .title {
    display: flex;
    font-size: 30px;
    font-weight: 700;
}
.content .block .top .title .tips {
    position: relative;
    border-radius: 50%;
    margin-right: 20px;
    display: flex;
    justify-content: center;
    color: #fff;
    align-items: center;
}
.content .block .top .title .tips img {
    width: 30px;
    height: 30px;
    padding: 10px;
    background-color: #0ABBB9;
    border-radius: 50%;
}
.content .block .top .title .tips .red {
    position: absolute;
    height: 14px;
    width: 14px;
    border-radius: 50%;
    background-color: red;
    top: 0;
    right: 0;
}
.content .block .top .right {
    display: flex;
}
.content .block .bottom {
    padding-top: 20px;
    display: block;
    font-size: 26px;
    color: #666;
    line-height: 42px;
}

.quit_team_notify,
.account_disabled_notify,
.jobseeker_dead_count_notify,
.jobseeker_soom_dead_count_notify {
    background-color: #fc6060 !important;
}
pre {
    font-family: "微软雅黑";
    white-space: pre-wrap;
}
.icon-a-jiantouxia,
.icon-a-jiantouyou {
    font-size: 26px;
    margin-left: 10px;
}
</style>